<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>大屏用户列表</p>
        <el-button type="primary" @click="dialogFormVisible = true">添加大屏用户</el-button>
      </div>
      <!-- <div class="query">
        <div class="queryLeft">
          <div>大屏用户账号：
            <el-input class="input" v-model="serchList.mechineName" size="medium" placeholder="请输入编号"></el-input>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="screenList(serchList,1)">查询</el-button>
        </div>
      </div> -->
      <!--      <div class="query">
        <div class="queryLeft">
          <div>
            <el-select class="select" size="medium" v-model="searchLevel" placeholder="请选择等级">
              <el-option v-for="item in levelListDate" :key="item.id" :label="item.levelName" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="screenListSearch()">查询</el-button>
        </div>
      </div> -->

      <el-table :data="screenData" v-loading="loading">
        <el-table-column prop="id" label="大屏用户ID" align="center" width="auto"></el-table-column>
        <el-table-column prop="section_name" label="大屏用户名称" align="center"> </el-table-column>
        <!-- <el-table-column prop="RegisterMobile" label="联系电话" align="center"></el-table-column> -->
        <!-- <el-table-column prop="GuaName" label="APP昵称" align="center"></el-table-column> -->
        <el-table-column prop="pname" label="所在城市" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.pname}}{{scope.row.cname}}{{scope.row.aname}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center" width="auto"></el-table-column>
        <!--        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="levelChange(scope.row)">添加城市</el-button>
            <el-button size="mini" style="margin: auto;" type="primary" plain @click="screenChange(scope.row)">更改所属
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
          background layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加大屏用户" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="screenAdd">
        <el-form-item label="请输入大屏用户名称:">
          <el-input v-model="screenAdd.name" style="width: 260px; float: left" placeholder="请输入大屏用户名称" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入大屏用户账号:">
          <el-input v-model="screenAdd.agent" style="width: 260px; float: left" placeholder="请输入大屏用户账号" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="大屏类型:">
          <el-select v-model="screenAdd.type" clearable placeholder="请选择" >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省市区:">
          <el-select v-model="screenAdd.province" placeholder="请选择" @change="city">
            <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="screenAdd.city" placeholder="请选择" @change="area">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="screenAdd.area" placeholder="请选择">
            <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="Adds">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    screenListAll, //大屏用户列表接口
    screenAdd, //添加大屏用户接口
    ssq, //省市区
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        regex: /^1[3456789]\d{9}$/, // 手机号正则
        screenData: [], // 大屏用户列表数据
        dialogFormVisible: false, // 添加大屏用户弹窗
        loading: false, //加载框
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        serchList: {}, //搜索栏查询
        screenAdd: {
          agent: '',
          name: '',
          province: '',
          city: '',
          area: '',
          type : '',
        }, // 添加新的大屏用户
        screenobj: {
          id: 0,
          agentid: 0
        }, //修改大屏用户的等级
        provinceList: [], //省
        cityList: [], //市
        areaList: [], //区
        cart: 'display:none',
        options: [{
          value: '1',
          label: '市'
        }, {
          value: '2',
          label: '区'
        }, ]
      }
    },
    mounted() {
      this.screenList(1) // 获取大屏用户列表
      this.province() // 总后台省市区学校接口
    },
    methods: {
      reset() { //重置按钮
        this.searchLevel = '',
          this.searchLevelOk = '',
          this.screenList(this.currpage)
      },
      screenList(currpage) { // 大屏用户列表
        this.loading = true
        const data = {}
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数

        screenListAll(data).then(res => {
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.screenData = res.data.list // 大屏用户列表
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      screenListSearch() { // 查询时候走这个方法
        this.searchLevelOk = this.searchLevel
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["lid"] = this.searchLevelOk // 每页条数

        screenList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.screenData = res.data.List // 大屏用户列表
          this.currpage = 1 //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      Adds() { // 点击添加大屏用户按钮
        if (!this.screenAdd.agent || !this.screenAdd.name  || !this.screenAdd.province || !this
          .screenAdd.city || !this.screenAdd.type) {
            this.$message.error('请补全信息')
        } else if(!this.regex.test(this.screenAdd.agent)){
            this.$message.error("手机号输入有误");
        } else if(this.screenAdd.type == 2 && !this
          .screenAdd.area){
            this.$message.error('请补全信息')
        }else {
          const data = {
            account: this.screenAdd.agent,
            account_name: this.screenAdd.name,
            province: this.screenAdd.province,
            city: this.screenAdd.city,
            area: this.screenAdd.area,
            type: this.screenAdd.type,
          }

          screenAdd(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.dialogFormVisible = false //关闭添加大屏用户弹窗
              this.screenList(this.currpage) // 刷新
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      handleCurrentChange(val) { // 分页
        this.screenList(val)
      },
      province() { // 总后台省接口
        ssq().then(res => {
          this.provinceList = res.data.province
        }).catch(err => {
          console.log(err)
        })
      },
      city() { // 总后台市接口
        this.screenAdd.city = '';
        this.screenAdd.area = '';
        const data = {
          pid: this.screenAdd.province
        }
        ssq(data).then(res => {
          this.cityList = res.data.city
        }).catch(err => {
          console.log(err)
        })
      },
      area() { // 总后台区接口
        this.screenAdd.area = '';
        const data = {
          cid: this.screenAdd.city
        }
        ssq(data).then(res => {
          this.areaList = res.data.area
        }).catch(err => {
          console.log(err)
        })
      },
      areashow() { // 显示区
        if(this.screenAdd.type == 1){
          this.cart = 'display:block';
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;

    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
      color: #000000;
      margin-left: 32px;
    }

    .btn {
      display: flex;
      align-items: center;
      width: 125px;
      height: 32px;
      opacity: 1;
      border-radius: 2px;
    }
  }

  .query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
      width: 80%;
      display: flex;

      div {
        width: 22%;
        text-align: left;

        .input,
        .select {
          width: 60%;
        }
      }
    }
  }

  .iconClick {
    color: #409EFF;
    margin-left: 3px;
    cursor: pointer;
  }
</style>
